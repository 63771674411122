import classNames from "classnames"
import { useField } from "formik"
import React, { useEffect } from "react"

import InputError from "./InputError"
import { Color } from "../../../../constants/V2/color"
import GatsbyStoryblokImage from "../GatsbyStoryblokImage"
import CheckmarkIcon from "../Icons/CheckmarkIcon"
import Typography from "../Typography"

export interface Props {
  label: string
  id: string
  image?: string
  className?: string
  disabled?: boolean
  inputClassName?: string
  ["data-test-id"]?: string
}

const CheckboxFormikField = ({
  label,
  id,
  image,
  className,
  inputClassName,
  disabled = false,
  ...props
}: Props) => {
  const [field, meta, helpers] = useField(id)
  const isChecked = !!field.value
  const hasError = meta.touched && meta.error

  const handleChange = (e: any) => {
    helpers.setValue(e.target.checked)
    helpers.setTouched(true)
    field.onChange(e)
  }

  useEffect(() => {
    helpers.setTouched(false)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <>
      <Checkbox
        id={id}
        label={label}
        image={image}
        name={field.name}
        value={field.value}
        className={className}
        isChecked={isChecked}
        disabled={disabled}
        onChange={handleChange}
      />
      {hasError && meta.error ? (
        <InputError
          className={inputClassName}
          error={meta.error}
          data-test-id={`input-error-${props["data-test-id"]}`}
        />
      ) : null}
    </>
  )
}

type CheckboxProps = {
  id: string
  label: string
  name?: string
  value?: any
  image?: string
  className?: string
  isChecked: boolean
  disabled?: boolean
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void
}

export const Checkbox = ({
  id,
  name,
  label,
  image,
  className,
  isChecked,
  disabled = false,
  value,
  onChange,
  ...props
}: CheckboxProps) => {
  return (
    <div className={classNames("group relative", className)}>
      <label
        htmlFor={id}
        className={classNames("flex cursor-pointer items-center", {
          "rounded-[10px] border border-solid pl-16": image,
          "border-blue": isChecked,
          "border-charcoal/10 group-hover:border-charcoal": !isChecked,
        })}
      >
        <input
          type="checkbox"
          id={id}
          name={name}
          checked={isChecked}
          onChange={onChange}
          disabled={disabled}
          className="peer absolute opacity-0"
          value={value}
          {...props}
        />
        <div
          className={classNames(
            "h-20 w-20 rounded-[6px] border",
            isChecked
              ? "border-blue bg-blue"
              : "border-charcoal/10 focus:border-charcoal group-hover:border-charcoal peer-focus:border-charcoal"
          )}
        >
          <CheckmarkIcon
            className={classNames(
              "ml-[3px] mt-[5px] h-[8px] w-[11px] stroke-white stroke-2 duration-200",
              isChecked ? "opacity-1" : "opacity-0"
            )}
            alt="checkbox-check"
          />
        </div>
        <Typography
          color={Color.Black}
          text={label}
          font="grotesk"
          size="body-md"
          weight={image ? "medium" : "book"}
          className="ml-10 mt-[2px]"
        />
        {image ? (
          <GatsbyStoryblokImage
            image={image}
            alt={label}
            width={100}
            className="ml-auto flex overflow-hidden rounded-[9px]"
            aspectRatio="4:3"
            quality={80}
            loadType="eager"
          />
        ) : null}
      </label>
    </div>
  )
}

export default CheckboxFormikField
