import React from "react"

type CircleExclamationIconProps = {
  className?: string
}

const CircleExclamationIcon = ({ className }: CircleExclamationIconProps) => {
  return (
    <svg
      fill="none"
      width={18}
      height={18}
      className={className}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        stroke="currentColor"
        d="M9 2.25A6.75 6.75 0 0 1 15.75 9 6.75 6.75 0 0 1 9 15.75a6.75 6.75 0 1 1 0-13.5ZM9 9.375v-3.75"
      />
      <path
        stroke="currentColor"
        d="M9 12a.188.188 0 1 0 .002.375.188.188 0 0 0-.003-.375"
      />
    </svg>
  )
}

export default CircleExclamationIcon
