import classNames from "classnames"
import React from "react"

import Typography from "./Typography"
import { Color } from "../../../constants/V2/color"

import { getBorderColorClass, getBackgroundColorClass } from "@utils/V2/color"

type Props = {
  text: string
  textColor: Color
  accentColor: Color
  style: "solid" | "bordered"
  className?: string
}

const Badge = ({ text, style, textColor, accentColor, className }: Props) => {
  return (
    <Typography
      text={text}
      weight="book"
      font="grotesk"
      color={textColor}
      size="subscript-lg"
      className={classNames(
        getBorderColorClass(accentColor),
        style === "solid" && getBackgroundColorClass(accentColor),
        "w-fit rounded-8 border px-8 pb-0.5 pt-4 uppercase",
        className
      )}
    />
  )
}

export default Badge
