import classNames from "classnames"
import React from "react"

import GatsbyStoryblokImage from "./GatsbyStoryblokImage"
import Typography from "./Typography"

import { Color } from "constants/V2/color"

type IconInfoBlockProps = {
  iconUrl?: string
  iconAlt?: string
  titleText: string
  bodyText: string
  size: "small" | "medium" | "large"
  textColor: Color
}

const IconInfoBlock = ({
  iconUrl,
  iconAlt,
  titleText,
  bodyText,
  size,
  textColor,
}: IconInfoBlockProps) => {
  return (
    <div
      className={classNames(
        "flex items-start",
        size === "small"
          ? "flex-row gap-16 md:flex-col"
          : size === "medium"
            ? "flex-row gap-16 md:flex-col lg:flex-row"
            : "flex-col gap-48 md:gap-64 lg:gap-88"
      )}
    >
      {iconUrl && (
        <GatsbyStoryblokImage
          image={iconUrl}
          alt={iconAlt || "Info Block Icon"}
          width={size === "small" ? 36 : size === "medium" ? 40 : 50}
        />
      )}

      <div className="flex flex-col gap-8">
        <Typography
          weight="medium"
          size={
            size === "small"
              ? "body-lg"
              : size === "medium"
                ? "lead-md"
                : "lead-lg"
          }
          font="grotesk"
          color={textColor}
          text={titleText}
          leadingNone
        />
        <Typography
          weight="book"
          size={
            size === "small"
              ? "body-sm"
              : size === "medium"
                ? "body-md"
                : "body-lg"
          }
          font="grotesk"
          color={textColor}
          text={bodyText}
        />
      </div>
    </div>
  )
}

export default IconInfoBlock
