import classNames from "classnames"
import React from "react"

type Props = {
  className?: string
  alt?: string
}

const Checkmark = ({ className, alt }: Props) => {
  return (
    <svg
      width={18}
      height={18}
      fill="none"
      viewBox="0 0 11 8"
      aria-labelledby={alt || ""}
      xmlns="http://www.w3.org/2000/svg"
      className={classNames("group", className)}
    >
      <path d="M1 3.2 4.8 7l6-6" />
    </svg>
  )
}

export default Checkmark
