import classNames from "classnames"
import React, { PropsWithChildren, forwardRef } from "react"

import { Color } from "../../../constants/V2/color"
import PillButton from "../../elements/V2/Buttons/PillButton"
import TextButton from "../../elements/V2/Buttons/TextButton"
import ArrowIcon from "../../elements/V2/Icons/ArrowIcon"
import Section from "../../elements/V2/Section"

import {
  getAccentColor,
  getBackgroundColorClass,
  getGradientFromColorClass,
  getGradientToColorClass,
} from "@utils/V2/color"

export type CTAProps = {
  ctaColor?: Color
  ctaText?: string
  ctaLink?: string
  ctaType?: "pill" | "text"
  trackingEvent?: string
  trackingEventKey?: string
  trackingEventValue?: string
}

type Props = {
  id?: string
  primaryBackgroundColor: Color
  secondaryBackgroundColor?: Color
  className?: string
} & CTAProps &
  React.DOMAttributes<HTMLDivElement>

const LayoutSection = forwardRef<HTMLDivElement, PropsWithChildren<Props>>(
  (
    {
      id,
      ctaType,
      ctaColor,
      ctaText,
      ctaLink,
      trackingEvent,
      trackingEventKey,
      trackingEventValue,
      primaryBackgroundColor,
      secondaryBackgroundColor,
      children,
      className,
      ...props
    },
    ref
  ) => {
    return (
      <Section
        id={id}
        ref={ref}
        className={classNames(
          "py-3xl -mb-lg rounded-t-lg relative",
          !secondaryBackgroundColor
            ? getBackgroundColorClass(primaryBackgroundColor)
            : [
                "bg-gradient-to-br",
                getGradientFromColorClass(primaryBackgroundColor),
                getGradientToColorClass(secondaryBackgroundColor),
              ],
          { "space-y-60": ctaText && ctaLink },
          className
        )}
        {...props}
      >
        {children}

        {ctaText && ctaLink ? (
          ctaType === "pill" ? (
            <PillButton
              size="large"
              style="solid"
              text={ctaText}
              linkUrl={ctaLink}
              className="mx-auto !flex"
              trackingEvent={trackingEvent}
              trackingEventKey={trackingEventKey}
              trackingEventValue={trackingEventValue}
              color={ctaColor || getAccentColor(primaryBackgroundColor)}
            />
          ) : ctaType === "text" ? (
            <TextButton
              style="icon"
              text={ctaText}
              linkUrl={ctaLink}
              icon={<ArrowIcon />}
              className="mx-auto !flex"
              trackingEvent={trackingEvent}
              trackingEventKey={trackingEventKey}
              trackingEventValue={trackingEventValue}
              color={ctaColor || getAccentColor(primaryBackgroundColor)}
            />
          ) : null
        ) : null}
      </Section>
    )
  }
)

LayoutSection.displayName = "LayoutSection"

export default LayoutSection
