import classNames from "classnames"
import React, { ReactNode, useCallback, useMemo } from "react"

import { Color } from "../../../../constants/V2/color"
import { trackSegmentEvent } from "../../../../utils/analytics"
import Link from "../../Link"
import ArrowIcon from "../Icons/ArrowIcon"

import {
  getAccentColor,
  getBackgroundHoverColorClass,
  getBorderColorClass,
  getTextColorClass,
  getTextHoverColorClass,
} from "@utils/V2/color"

type Props = {
  color: Color
  linkUrl?: string
  mobileLinkUrl?: string
  onClick?: () => void
  icon?: ReactNode
  disabled?: boolean
  trackingEvent?: string
  trackingEventKey?: string
  trackingEventValue?: string
  className?: string
}

const CircledIconButton = ({
  color,
  linkUrl,
  mobileLinkUrl,
  onClick,
  icon = <ArrowIcon animateOnHover={false} />,
  disabled = false,
  trackingEvent,
  trackingEventKey,
  trackingEventValue,
  className,
}: Props) => {
  const handleClick = useCallback(
    (e: React.MouseEvent<HTMLElement>, link?: string) => {
      if (trackingEvent) {
        const event =
          trackingEventKey && trackingEventValue
            ? { [trackingEventKey]: trackingEventValue }
            : undefined
        trackSegmentEvent(trackingEvent, event)
      }

      if (onClick) {
        onClick()
      } else if (link && link.startsWith("#")) {
        e.preventDefault()
        const element = document.querySelector(link)
        if (element) {
          element.scrollIntoView({ behavior: "smooth" })
        }
      }
    },
    [trackingEvent, onClick, trackingEventKey, trackingEventValue]
  )

  const buttonClasses = useMemo(
    () =>
      classNames(
        getBorderColorClass(color),
        getBackgroundHoverColorClass(color),
        "group block w-fit rounded-full border p-[6px] sm:p-[7px] md:p-8 lg:p-[12px] transition-colors duration-300",
        linkUrl
          ? { "opacity-50 pointer-events-none": disabled }
          : "disabled:opacity-50 disabled:pointer-events-none",
        className
      ),
    [color, disabled, linkUrl, className]
  )

  const buttonContent = (
    <div
      className={classNames(
        getTextColorClass(color),
        getTextHoverColorClass(getAccentColor(color)),
        "group flex h-16 w-16 items-center justify-center transition-colors duration-300 sm:h-[20px] sm:w-[20px] md:h-24 md:w-24"
      )}
    >
      {icon}
    </div>
  )

  if (linkUrl && mobileLinkUrl) {
    return (
      <>
        <Link
          link={linkUrl}
          onClick={(e) => handleClick(e, linkUrl)}
          className={classNames("hidden md:block", buttonClasses)}
        >
          {buttonContent}
        </Link>
        <Link
          link={mobileLinkUrl}
          onClick={(e) => handleClick(e, mobileLinkUrl)}
          className={classNames("block md:hidden", buttonClasses)}
        >
          {buttonContent}
        </Link>
      </>
    )
  } else if (linkUrl && !mobileLinkUrl) {
    return (
      <Link
        link={linkUrl}
        onClick={(e) => handleClick(e, linkUrl)}
        className={buttonClasses}
      >
        {buttonContent}
      </Link>
    )
  }

  return (
    <button
      type="button"
      onClick={(e) => handleClick(e)}
      className={buttonClasses}
      disabled={disabled}
      aria-label="Circled Button"
    >
      {buttonContent}
    </button>
  )
}

export default CircledIconButton
