import classNames from "classnames"
import React from "react"

import StarIcon from "./Icons/StarIcon"
import Typography from "./Typography"
import { Color } from "../../../constants/V2/color"

import { getBorderColorClass } from "@utils/V2/color"

type Props = {
  color: Color
  classname?: string
  text: string
  highlightedWord: string
  hasBorder?: boolean
}

const splitText = (text: string, highlightedWord: string): [string, string] => {
  if (!highlightedWord.trim() || !text.includes(highlightedWord))
    return [text, ""]

  const [firstSection, ...rest] = text.split(highlightedWord)
  const secondSection = rest.join(highlightedWord)

  return [firstSection, secondSection]
}

const Callout = ({
  color = Color.Charcoal,
  classname,
  text,
  highlightedWord,
  hasBorder = true,
}: Props) => {
  const [firstSection, secondSection] = splitText(text, highlightedWord)
  return (
    <div
      className={classNames(
        getBorderColorClass(color),
        "flex w-fit flex-row items-center justify-center gap-x-4",
        {
          "rounded-40 border border-black border-opacity-10 px-8 py-[7px] md:px-16":
            hasBorder,
        },
        classname
      )}
    >
      <StarIcon color={color} />
      <div className={classNames("flex gap-x-4", { "mt-[3px]": hasBorder })}>
        <Typography
          color={color}
          text={firstSection}
          font="grotesk"
          size="body-md"
          weight="book"
        />
        <Typography
          color={Color.Blue}
          text={text.includes(highlightedWord) ? highlightedWord : ""}
          font="grotesk"
          size="body-md"
          weight="book"
        />
        <Typography
          color={color}
          text={secondSection}
          font="grotesk"
          size="body-md"
          weight="book"
        />
      </div>
    </div>
  )
}

export default Callout
